:root {
    --green-color: #38d090;
}


@font-face {
    font-family: 'Probok';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('./fonts/Commons/TTCommons-ExtraLight.ttf') format('woff2');
}

@font-face {
    font-family: 'Probok';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url('./fonts/Commons/TTCommons-ExtraLight.ttf') format('woff2');
}

@font-face {
    font-family: 'Probok';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('./fonts/Commons/TTCommons-ExtraLight.ttf') format('woff2');
}

@font-face {
    font-family: 'Probok';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('./fonts/Commons/TTCommons-Light.ttf') format('woff2');
}

@font-face {
    font-family: 'Probok';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('./fonts/Commons/TTCommons-Regular.ttf') format('woff2');

}

@font-face {
    font-family: 'Probok';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('./fonts/Commons/TTCommons-Medium.ttf') format('woff2');

}

@font-face {
    font-family: 'Probok';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('./fonts/Commons/TTCommons-Bold.ttf') format('woff2');

}

@font-face {
    font-family: 'Probok';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('./fonts/Commons/TTCommons-Black.ttf') format('woff2');

}

/* Обычный */
/* @font-face {
    font-family: 'Onest';
    src: url('./fonts/OnestRegular1602-hint.ttf');
    font-style: normal;
}

/* Средний */
/* @font-face {
    font-family: 'Onest Medium';
    src: url('./fonts/OnestMedium1602-hint.ttf');
} */

/* Жирный */
/* @font-face {
    font-family: 'Onest Bold';
    src: url('./fonts/OnestBold1602-hint.ttf');
} */

* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-family: 'Probok', sans-serif;
}

html {
    height: 100%;
    width: 100%;
}

a {
    cursor: pointer;
}

.section_pn {
    margin-bottom: 56px;
}

#header {
    display: flex;
    align-items: center;
    border-bottom: 0.75px solid rgba(0, 0, 0, 0.24);
    padding: 0 24px;
    height: 72px;
}

.sticky {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    width: 100%;
    z-index: 20;
    /* background: #fff; */
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(16.5px);
    -webkit-backdrop-filter: blur(16.5px);
}

.sticky+.content {
    position: relative;
    margin-bottom: 200px;
}

.header_menu {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header_menu-left {
    display: flex;
    justify-content: flex-start;
}

.header_menu-right {
    display: flex;
    justify-content: flex-end;
}

.header_menu-left a, .header_menu-right a {
    text-decoration: none;
    color: black;
    font-size: 20px;
    display: flex;
    align-items: center;
    transition: 0.16s ease-in;
}

.a-hover:hover {
    color: var(--green-color);
}

.f-bold {
    font-weight: 800;
}

.f-medium {
    font-weight: 600;
}

.f-normal {
    font-weight: 500;
}

.container-default {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
}

.header_banner {
    width: 100%;
    height: 544px;
}

.row {
    height: 100%;
}

.header_banner-text {
    width: 50%;
    max-width: 620px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.h-text {
    padding: 64px;
}

.def-swiper {
    height: 100%;
}

.def-swiper>div {
    width: 100%;
    height: 100%;
}

.h-text h1 {
    font-size: 48px;
    margin-bottom: 24px;
}

.h-text p {
    width: 100%;
    max-width: 412px;
    font-size: 20px;
}

.social_media-banner {
    display: flex;
    margin-top: 24px;
}

.social_media-banner>.social_media-link:not(:last-child) {
    margin-right: 16px;
}

.social_media-link {
    display: flex;
    align-items: center;
}

.social_media-link img {
    width: 48px;
}

.header_banner-video_1 {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: url('../video/video.gif') no-repeat;
    background-size: cover;
}

.header_banner-video_2 {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: url('../video/video.gif') no-repeat;
    background-size: cover;
}

.shumsky_banner {
    background-color: #f2f2f2;
    height: 544px;
    margin-bottom: 56px;
    width: 100%;
}

.shumsky_banner-text {
    position: relative;
    width: 50%;
    max-width: 620px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    z-index: 1;

}

.shumsky_banner-img {
    background: url('../img/shumskiy.png') no-repeat;
    background-position: 100% 100%;
    background-size: contain;
    height: 100%;
    overflow: hidden;
    position: absolute;
    right: 24px;
    width: 30%;
    z-index: 0;
    bottom: 24px;
}

.c-white {
    color: #fff;
}

.title_group {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.section_title .title {
    font-size: 48px;
    font-weight: 800;
    padding: 0 56px;
}

.section_title .pre-text {
    font-size: 20px;
    padding: 0 56px;
    max-width: 820px;
    font-weight: 500;
}

.title_info {
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 0px 56px 56px 56px; */
    margin-right: 56px;
}

.title_info span {
    margin-right: 16px;
    font-weight: 500;
    font-size: 12.5px;
    opacity: 0.56;
}

.pre-text a {
    text-decoration: underline;
    color: black;
    transition: 0.24s ease-in;
}

.pre-text a:hover {
    font-weight: 500;
    color: var(--green-color);
}

.section_title {
    margin-bottom: 32px;
}

.section_content {
    position: relative;
    width: 100%;
    height: 100%;
}

.achivement_items {
    padding: 0 56px;
}

.achive_item b {
    color: var(--green-color);
    font-size: 20px;
    margin-right: 16px;
}

.achive_item {
    font-size: 20px;
    margin-bottom: 24px;
    font-weight: 500;
}


.section_button {
    padding: 0 56px;
    height: 56px;
    display: flex;
    align-items: center;
}

.sb_link-underline {
    font-size: 20px;
    text-decoration: underline;
    font-weight: 600;
    color: #000;
    transition: 0.24s ease-in;
}

.sb_link-underline:hover {
    color: var(--green-color);
}

.achivements {
    height: 100%;
}

.circle_items {
    padding: 0 56px;
}

.cirlce-item img {
    width: 100%;
}

.swiper_slider-section {
    margin-top: 56px;
    /* height: 512px; */
}

.fade-in {
    -webkit-animation: fade-in-fwd 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-fwd 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in-fwd {
    0% {
        -webkit-transform: translateZ(-80px);
        transform: translateZ(-80px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

@keyframes fade-in-fwd {
    0% {
        -webkit-transform: translateZ(-80px);
        transform: translateZ(-80px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

.fade-in-top {
    -webkit-animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in-top {
    0% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fade-in-top {
    0% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}


.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.reals_slider {
    position: relative;
    left: 32px;
    height: 100%;
    padding: 0 24px;

}

.reals_slider .swiper-container {
    width: 100%;

    height: 300px;
    margin: 20px auto;
}

.item_reals {
    width: 100%;
    max-width: 208px;
    height: 275px;
    background: var(--green-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px 4px;
}

.item_reals img {
    border-radius: 4px 4px;
}

.achivements {
    position: relative;
    overflow: hidden;
}

.reals_slider .swiper {
    width: 100%;
    max-width: 100%;
}

.reals_slider .swiper .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 208px;
    transition: none;
    height: 324px;
}

.swiper-slide .cont {
    text-align: center;
}

.test {
    cursor: grab;
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 208px!important; */
}

.test:last-child {
    margin-right: 30px;
}

.playVideo {
    position: absolute;
    border: 2px solid #fff;
    border-radius: 100px 100px;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(255, 255, 255);
}

.videoName {
    bottom: 0;
    font-size: 16px;
    font-weight: 600;
    left: 0;
    position: absolute;
    z-index: 9;
    height: 64px;
    text-align: left;
    text-decoration: none;
    color: black;
}






/*youtube slider*/
.youtube_slider {
    position: relative;
    left: 32px;
    height: 100%;
    padding: 0 24px;

}

.youtube_slider .swiper .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 412px;
    height: 303px;
}

.item_youtube {
    width: 100%;
    height: 224px;
    background: var(--green-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px 4px;
}


/*news slider*/
.news_slider {
    position: relative;
    left: 32px;
    height: 100%;
    padding: 0 24px;

}

.news_slider .swiper .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 412px;
    transition: none;
    height: 356px;
}

.item_news {
    position: relative;
    width: 100%;
    height: 224px;
    background: var(--green-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px 4px;
}

.newsName {
    position: absolute;
    bottom: -56px;
    left: 0;
    z-index: 9;
    font-weight: 600;
    font-size: 20px;
    text-align: left;
    height: 48px;
}

.news-next {
    position: absolute;
    bottom: -112px;
    left: 0;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 8px;
    font-weight: 600;
    color: var(--green-color);
    border: 2px solid var(--green-color);
    text-decoration: none;
    font-size: 16px;
    transition: 0.24s ease-in;
}

.news-next:hover {
    background-color: var(--green-color);
    color: #fff;
}


.swiper {
    z-index: 0;
}

.dialogText {
    font-weight: 500;
}

.sideMenu {
    width: 100%;
}

.sideMenu>a {
    display: flex;
    font-size: 20px;
    font-weight: 600;
    width: 100%;
    height: 56px;
    transition: 0.24s ease-in;
    align-items: center;
}

.sideMenu>a:hover {
    color: var(--green-color);
}

.ub-w_620px {
    width: 412px;
}

footer {
    background: #F6F6F6;
    border-top: 0.75px solid rgba(0, 0, 0, 0.24);
    height: 224px;
    padding: 56px 56px;
}

.footer-title {
    margin-bottom: 32px;
}

.footer-content {
    width: 100%;
    max-width: 412px;
}

.footer-title p {
    font-size: 20px;
    font-weight: 600;
    opacity: 0.56;
}

.footer-content {
    display: flex;
    align-items: center;

}

.footer-content .row>div {
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 500;
}

.swiper_slider-section .swiper .swiper-slide {
    width: 100%;
    max-width: 860px;
    /* height: 412px; */
}

.swiper {
    cursor: grab;
}



/*video youtube*/
.video_Modal {

    position: absolute;
    z-index: 9999;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.32);
    display: flex;
    align-items: center;
    justify-content: center;
}

.video_container {
    width: 100%;
    position: relative;
    background: white;
}

.video_container.youtube {
    width: 100%;
    max-width: 612px;
    max-height: 324px;
}

.video_controls {
    height: 32px;
    background: #000;
    width: 100%;
}

.youtube div video {}

.video-react-video, .video_container {
    border-radius: 4px;
}

.video-react.video-react-fluid, .video-react.video-react-16-9, .video-react.video-react-4-3 {

    border-radius: 4px;
}

.video_container {
    box-shadow: 0 0 56px rgba(0, 0, 0, 0.32);
}




.card_location {
    position: fixed;
    z-index: 99999;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    max-width: 100%;
    margin: 0 auto;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    background-color: rgba(0, 0, 0, 0.86);
    overflow: hidden;
}

.card_location_two {
    position: fixed;
    z-index: 99999;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    max-width: 100%;
    margin: 0 auto;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    background-color: rgba(0, 0, 0, 0.86);
    overflow: hidden;
}

.card_location_two.active-card_two {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.card_location.active-card {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.card_content {
    position: relative;
    width: 60%;
    min-width: 356px;
    box-shadow: 0 0 128px var(--green-color);
}

.card_content_two {
    position: relative;
    width: 100%;
    max-width: 280px;
    box-shadow: 0 0 128px var(--green-color);
    border-radius: 8px;
}

.fade-in {
    -webkit-animation: fade-in 0.16s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in 0.16s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}










.card_close {
    width: 56px;
    height: 56px;
    background: var(--green-color);
    position: absolute;
    z-index: 9999;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
}

.section_post {
    position: relative;
    padding: 56px 24px;
    max-width: 720px;
    margin: 0 auto;
    /* border-left: 1px solid rgba(0, 0, 0, 0.24);
    border-right: 1px solid rgba(0, 0, 0, 0.24); */
    height: 100%;
}

.post_header {
    background: rgba(0, 0, 0, 0.02);
    border-radius: 8px;
    margin-bottom: 32px;
}

.content_post h1 {
    font-weight: 800;
    font-size: 32px;
    max-width: 512px;
    margin-bottom: 32px;
}

.content_post .text_post {
    font-size: 20px;
    line-height: 24px;
    max-width: 720px;
}


.post_item-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px 16px;
}

.post_item-header p {
    font-size: 12.5px;
    font-weight: 600;
    opacity: 0.72;
}

.post_photo {
    position: relative;
    max-width: 720px;
    height: 356px;

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 32px;
    border-radius: 8px;
}

.content_post {
    position: relative;
}

.post_photo img {
    height: 100%;
    transform: scale(1.2);
}

.text_post {
    margin-bottom: 64px;
}

.text_post p {
    line-height: 28px;
    font-size: 20px;
    font-weight: 500;
}

.text_post ul {
    list-style-type: none;
}

.text_post ul li {
    margin-bottom: 8px;
}

.text_post span {
    border-radius: 8px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    background-color: #F5FBF8;
    border: 1px solid #52BD95;
    color: #317159;
    font-weight: 600;
    width: 100%;
    font-size: 16.5px;
    padding: 16px 32px;
    text-align: center;
    justify-content: center;
    margin-top: 16px;
}

.text_post p {
    max-width: 532px;
    word-wrap: break-word;
    white-space: 10px;
    vertical-align: baseline;
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
    text-align: justify;
}

.footer_post-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 8px;
}

.footer_post-text {
    font-size: 16px;
}

.footer_post-text a {
    text-decoration: none;
    color: var(--green-color);
    border-bottom: 2px solid var(--green-color);
    padding: 2px;
    font-weight: 600;
}

br {
    margin-bottom: 24px;
}

@media screen and (max-width: 720px) {
    .def-swiper {
        height: 100%;
    }

    .def-swiper>div {
        background-size: cover;
        height: 226px !important;
    }

    .header_banner-video_1, .header_banner-video_2 {
        position: absolute;
        background: linear-gradient(rgba(0, 0, 0, 0.32), rgba(0, 0, 0, 0.32)), url('../video/video.gif') no-repeat;
        background-size: cover;
        z-index: -1;
        opacity: 1;
    }

    .white-banner {
        color: white !important;
    }

    .h-text {
        padding: 24px 24px;

    }

    .h-text h1 {
        font-size: 32px;
        margin-bottom: 24px;
        color: black;
    }

    .h-text p {
        color: black;
        font-size: 16px;
    }

    .social_media-banner {
        max-width: 256px;
        background: white;
        padding: 8px 8px;
        border-radius: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .shumsky_banner-img {
        display: none;
    }

    .shumsky_banner {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 356px;
        height: 100%;
    }

    .section_title .title {
        font-size: 40px;
        font-weight: 800;
        padding: 0 24px;
    }

    .achivement_items {
        padding: 0 24px;
    }

    .achive_item {
        font-size: 16px;
        margin-bottom: 24px;
        font-weight: 500;
    }

    .section_button {
        padding: 0 24px;
    }

    .section_title .pre-text {
        padding: 0 24px;
        font-size: 16px;
    }

    .circle_items {
        padding: 0 24px;
    }

    .reals_slider {
        position: relative;
        left: 24px;
        height: 100%;
        padding: 0;
    }

    .youtube_slider {
        position: relative;
        left: 24px;
        height: 100%;
        padding: 0;
    }

    .news_slider {
        position: relative;
        left: 24px;
        height: 100%;
        padding: 0;
    }

    .news_slider .swiper .swiper-slide, .youtube_slider .swiper .swiper-slide {
        width: 312px;
    }

    .swiper_slider-section .swiper .swiper-slide {
        height: 312px;
    }
}

.close_menu {
    display: flex;
    font-size: 20px;
    font-weight: 600;
    width: 100%;
    height: 56px;
    transition: 0.24s ease-in;
    align-items: center;
    background: var(--green-color);
    justify-content: center;
    color: white;
    cursor: pointer;
}

.sideMenu a {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.56);
}




.swiper-button-prev::after {
    margin-left: -3px;
}

.swiper-button-next::after {
    margin-left: 3px;
}

.swiper-button-next::after, .swiper-button-prev::after, .swiper-button-next::after, .swiper-button-prev::after {
    font-family: swiper-icons;
    font-size: var(--swiper-navigation-size);
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
    font-size: 16px;
    color: white;
    font-weight: bold;

}

.swiper-button-prev, .swiper-rtl .swiper-button-next {
    left: 16.3%;
    right: auto;
    background: #38d090;
    padding: 8px 8px;
    width: 32px;
    height: 32px;
    border-radius: 100px;
}

.swiper-button-next, .swiper-rtl .swiper-button-prev {
    right: 16.3%;
    left: auto;
    background: #38d090;
    padding: 8px 8px;
    width: 32px;
    height: 32px;
    border-radius: 100px;
}

.achive_page {
    margin-top: 64px;
    padding: 0 8px;
    margin-bottom: 128px;
}

.page-section_title {
    margin-bottom: 32px;
    padding: 0 32px;
}

.page-section_title .title {
    font-size: 48px;
    font-weight: 800;
    padding: 0 0;
}

.achive-items>div {
    position: relative;
    height: 100%;
    margin-bottom: 56px;
    min-height: 312px;
}

.title-acvive p {
    color: #1e1e1e;
    font-size: 30px;
    font-family: 'TTCommons', Arial, sans-serif;
    line-height: 1.55;
    font-weight: 500;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
}

.text-achive p {
    color: #1e1e1e;
    font-size: 16px;
    font-family: 'TTCommons', Arial, sans-serif;
    line-height: 1.55;
    font-weight: 400;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
}

.achive-slider {
    height: 312px;
}






.achive-slider .swiper-button-prev, .swiper-rtl .swiper-button-next {

    left: 4%;
    right: auto;
    background: #38d090;
    padding: 8px 8px;
    width: 32px;
    height: 32px;
    border-radius: 100px;

}

.achive-slider .swiper-button-next, .swiper-rtl .swiper-button-prev {
    right: 4%;
    left: auto;
    background: #38d090;
    padding: 8px 8px;
    width: 32px;
    height: 32px;
    border-radius: 100px;
}